<template>
  <div class="view pa24">
    <el-button
        class="mr10"
        type="primary"
        @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false
      "
    >+ 添加顶部轮播图</el-button>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="selection" width="50"/>
        <el-table-column prop="name" align="center" label="名称" show-overflow-tooltip/>
<!--        <el-table-column prop="url" align="center" label="跳转链接" show-overflow-tooltip/>-->
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，商品排序越靠前" placement="top-start">
              <el-input v-model="scope.row.orderBy" min="0" @input="scope.row.orderBy=scope.row.orderBy.replace(/^0(0+)|[^\d]+/g,'')"  placeholder="数值越大，商品排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="uploadTime" align="center" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
                @change="saveStatus(scope.row)"
                v-model="scope.row.status"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <!--            <el-button-->
            <!--                type="text"-->
            <!--                @click="-->
            <!--                edit(scope.row, false);-->
            <!--                bEdit = true;-->
            <!--              "-->
            <!--            >详情</el-button-->
            <!--            >-->
            <el-button
                type="text"
                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
            >编辑</el-button
            >
            <!-- <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @onConfirm="del(scope.row.videoId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
            >
              <el-button
                slot="reference"
                class="ml10"
                style="color: #fd634e"
                type="text"
                >删除</el-button
              >
            </el-popconfirm> -->
            <customPopconfirm
                confirm-button-text="确定"
                k              cancel-button-text="取消"
                @confirm="del(scope.row.rotationId)"
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <!--    :destroy-on-close="true"-->
    <el-dialog
        title="添加顶部轮播图"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
      >
        <el-form-item label="轮播名称" prop="title">
          <el-input clearable v-model="ruleForm.title" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="coverImg">
          <el-upload :action="$store.state.uploadingUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :before-upload="beforeAvatarUploadImg"
                     :file-list="fileList"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              图片上传比例为2:1，支持格式png、jpg、jpeg，大小不超过1M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input clearable v-model="ruleForm.sort"  @input="ruleForm.sort=ruleForm.sort.replace(/^0(0+)|[^\d]+/g,'')"  min="0" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="isState">
          <el-radio-group v-model="ruleForm.isState">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
<!--        <el-form-item label="跳转链接" prop="skipLink">-->
<!--          <el-radio-group v-model="ruleForm.skipLink">-->
<!--            <el-radio :label="0">跳转小程序内部页面</el-radio>-->
<!--            <el-radio :label="1">跳转到网页</el-radio>-->
<!--            <el-radio :label="2">跳转到外部小程序页面</el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
<!--        <el-form-item v-if="ruleForm.skipLink == 0" style="position: relative">-->
<!--          <el-input v-model="ruleForm.link"></el-input>-->
<!--          <span style="position: absolute;right: 2px;top:2px;background: #FFFFFF;height: 30px;width: 100px;text-align: center;cursor: pointer">选择</span>-->
<!--        </el-form-item>-->
<!--        <el-form-item v-if="ruleForm.skipLink == 1" style="position: relative">-->
<!--          <el-input v-model="ruleForm.link" placeholder="请输入URL..."></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item v-if="ruleForm.skipLink == 2" label="小程序APPID">-->
<!--          <el-input v-model="ruleForm.appId"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item v-if="ruleForm.skipLink == 2" label="跳转路径">-->
<!--          <el-input v-model="ruleForm.link"></el-input>-->
<!--        </el-form-item>-->
        <!--        <el-form-item v-if="ruleForm.skipLink == 2" label="备注">-->
        <!--          <el-input v-model="ruleForm.link"></el-input>-->
        <!--        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {addRotation,selectRotationList,upRotation,delRotation} from "@/api/content";
let ac;
export default {
  name:'topShuffling',
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      isUpdate:true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        title: "",
        sort: "",
        isState: 0,
        skipLink: 0,
        link:'',
        coverImg:'',
        appId:''
      },
      rules: {
        title: [{ required: true, message: "请输入轮播名称", trigger: "blur" }],
        coverImg: [{ required: true, message: "请上传图片" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        isState: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },
      jurisdictionA:'',
      jurisdictionB:'',
      rotationId:'',
      ossUrl: this.$store.state.ossUrl,
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  computed: {
    ...mapState(['routerArr']),
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  watch:{
    routerArr(val){
      console.log(val)
      this.jurisdictionA = val.includes('080')
      this.jurisdictionB = val.includes('081')
      if(this.jurisdictionA || this.jurisdictionB){
        this.queryPage()
      }
    }
  },
  mounted(){
    this.queryPage();
  },

  methods: {
    async queryPage() {
      console.log('00000000000')
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        type:2
      };
      try {
        this.loading = true;
        const result = await selectRotationList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          if (list[i].status == 0) {
            list[i].status = true
          }else {
            list[i].status = false
          }
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.ruleForm.coverImg = "";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm.title = data.name;
      this.ruleForm.sort = data.orderBy;
      this.ruleForm.isState = data.status?0:1;
      this.ruleForm.skipLink = data.jumpType;
      this.ruleForm.link = data.url;
      this.ruleForm.coverImg = data.homeUrl;
      this.ruleForm.appId = data.appId;
      this.fileList = [{ url: data.homeUrl, uid: 1 }];
      this.bdetermine = b;
      this.bEdit = b;
      this.rotationId = data.rotationId
    },
    /**@method 添加视频 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              type:2,
              name:this.ruleForm.title,
              homeUrl:this.ruleForm.coverImg,
              orderBy:this.ruleForm.sort,
              status:this.ruleForm.isState,
              // jumpType:this.ruleForm.skipLink,
              // url:this.ruleForm.link
            };
            // if (this.ruleForm.skipLink == 2) {
            //   data.appId = this.ruleForm.appId
            // }
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              data.rotationId = this.rotationId
              await upRotation(data);
            } else {
              await addRotation(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delRotation({ rotationId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 上架视频 */
    async updateData(val) {
      let data = {
        videoId: val.videoId,
        state: val.state ? 0 : 1,
      };
      await updateData(data);
      await this.queryPage();
    },
    async saveSort(row){
      if(row && row.sort != ""){
        let ajax = {
          rotationId:row.rotationId,
          orderBy:row.orderBy
        }
        const result = await upRotation(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    async saveStatus(row){
      if(row){
        let ajax = {
          rotationId:row.rotationId,
          status:row.status?0:1
        }
        const result = await upRotation(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "不能为空",
        });
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        sort: "",
        isState: 0,
        skipLink: 0,
        link:'',
        coverImg:'',
        appId:''
      }),
          this.centerDialogVisible = false;
        this.fileList = []
    },
    beforeAvatarUploadImg(file){
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1M!");
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  //line-height: 175px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>




