<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input
            style="width: 200px!important;"
            class="w120 mb10 mr10"
            placeholder="请输入捐赠人"
            v-model="filterName"
            clearable
        />
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
<!--      <div style="margin-left: auto">-->
<!--        <el-button class="ma" type="primary" @click="centerDialogVisible = true"-->
<!--        >+ 日常捐赠</el-button-->
<!--        >-->
<!--      </div>-->
    </div>
    <commonTable
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection" />
        <el-table-column prop="userName" align="center" label="捐赠人" />
        <el-table-column prop="money" align="center" label="捐赠金额" />
        <el-table-column prop="content" align="center" label="捐赠说明" />
        <el-table-column prop="createTime" align="center" label="捐赠时间" />
<!--        <el-table-column align="center" label="操作">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button type="primary" plain @click="open(scope.row)"-->
<!--            >编辑</el-button-->
<!--            >-->
<!--            <customPopconfirm-->
<!--                class="ma ml10 mb10"-->
<!--                confirm-button-text="确定"-->
<!--                cancel-button-text="取消"-->
<!--                @confirm="del(scope.row.donationDailyId)"-->
<!--                title="确认删除？">-->
<!--              <el-button type="danger" slot="reference" plain>删除</el-button>-->
<!--            </customPopconfirm>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </template>
    </commonTable>
    <el-dialog
        title="日常捐赠"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
      >
        <el-form-item label="捐赠人" prop="name">
          <el-input clearable v-model="ruleForm.name" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="捐赠金额" prop="money">
          <el-input clearable v-model="ruleForm.money"  :maxlength="inputMaxL" @input="inputMaxL = /^\d+\.?\d{0,1}$/.test(ruleForm.money) ? null : ruleForm.money.length - 1"></el-input>
        </el-form-item>
        <el-form-item label="捐赠说明" prop="content">
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              v-model="ruleForm.content"
              ref="myQuillEditor"
              @ready="ready($event)"
              :options="editorOption"
              @change="onEditorChange($event)"
          />
          <span class="wordNumber">{{TiLength}}/10000</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { lineHeightStyle } from "@/utils/lineHeight.js";
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {addDonationDaily,selectDonationDailyList,upDonationDaily,delDonationDaily} from "@/api/content";
export default {
  name: "donationList",
  components: {
    commonTable,
    // customPopconfirm
  },
  props:{
    jurisdiction:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputMaxL:10,
      TiLength:0,
      editorOption: quillConfig,
      centerDialogVisible: false,
      ruleForm: {
        name: "",
        money: "",
        content:""
      },
      rules: {
        name: [{ required: true, message: "请输入捐赠人", trigger: "blur" }],
        money: [{ required: true, message: "请输入捐赠金额", trigger: "blur" }],
        content: [{ required: true, message: "请输入捐赠说明", trigger: "blur" }],
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      options: [{ label: "全部", value: 1 }],
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading:false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      upDate:1,
      donationDailyId:''
    };
  },
  mounted(){
    this.queryPage();
  },
  methods: {
    ready() {
      Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
    },
    onEditorChange(e){
      // e.quill.deleteText(10000,0);
      if(this.ruleForm.details==''){
        this.TiLength=0
      }else{
        this.TiLength=e.quill.getLength()-1
      }
    },
    async queryPage(payload) {
      let data = {
        ifPay:0,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectDonationDailyList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          list[i].money = (list[i].money/100).toFixed(2)
          list[i].createTime = this.renderTime(list[i].createTime)
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    //搜索
    async filterNames() {
      this.currentPage = 1;
      let data = {
        userName:this.filterName
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    determine(){
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              userName:this.ruleForm.name,
              money: parseInt(this.ruleForm.money * 100),
              content:this.ruleForm.content,
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addDonationDaily(data);
            }else {
              data.donationDailyId = this.donationDailyId
              await upDonationDaily(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //删除弹窗
    async del(val) {
      try {
        await delDonationDaily({ donationDailyId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //分类弹窗
    open(row) {
      this.ruleForm.name = row.name;
      this.ruleForm.money = row.money;
      this.ruleForm.content = row.content;
      this.donationDailyId = row.donationDailyId
      this.upDate = 2
      this.centerDialogVisible = true
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        name: "",
        money: "",
        content:""
      }),
          this.centerDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>


