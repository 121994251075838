<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input
            style="width: 200px!important;"
            class="w120 mb10 mr10"
            placeholder="项目名称"
            v-model="filterName"
            clearable
        />
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <!-- <el-button class="ma mr10 mb10" @click="delAll">批量删除</el-button> -->
        <el-button class="ma" type="primary" @click="centerDialogVisible = true,upDate=1"
        >+ 添加捐赠项目</el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection" />
        <el-table-column prop="name" align="center" label="项目名称" />
        <el-table-column prop="name" align="center" label="图片" >
          <template slot-scope="scope">
            <el-image
                style="width: 80px; height: 40px"
                :src="scope.row.thumbnail"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="targetMoney" align="center" label="目标金额" />
        <el-table-column prop="completeMoney" align="center" label="已捐金额" />
        <el-table-column prop="progress" align="center" label="完成度">
<!--          <template slot-scope="scope">-->
<!--            <el-tooltip class="item" effect="dark" content="数值越大，分类排序越靠前" placement="top-start">-->
<!--              <el-input v-model="scope.row.sort" placeholder="数值越大，分类排序越靠前" @change="saveSort(scope.row)"/>-->
<!--            </el-tooltip>-->
<!--          </template>-->
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="发布时间" />
        <el-table-column prop="status" align="center" label="状态" />
        <el-table-column align="center" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button type="primary" v-if="scope.row.status!='已完成'" plain @click="open(scope.row)"
            >编辑</el-button
            >
            <el-button v-if="scope.row.status!='未开始'" type="info" plain @click="jilu(scope.row)"
            >捐赠记录</el-button
            >
            <customPopconfirm
                v-if="scope.row.status=='未开始'"
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.donationId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
            <customPopconfirm
                v-if="scope.row.status=='未开始'"
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="toStart(scope.row.donationId)"
                title="确认开始？">
              <el-button type="success"   slot="reference" plain>开始</el-button>
            </customPopconfirm>
            <customPopconfirm
                v-if="scope.row.status=='进行中'"
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="toEnd(scope.row.donationId)"
                title="确认结束？">
              <el-button type="success"   slot="reference" plain>结束</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="添加捐赠项目"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          ref="ruleForm" :model="ruleForm" label-width="90px"  :rules="rules" class="view pa24" label-position="left"
      >
        <el-form-item prop="projectTitle" label="项目名称">
          <el-input
              clearable
              v-model="ruleForm.projectTitle"
              style="width: 100%"
              placeholder="请输入项目名称"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="money" label="目标金额">
          <el-input
              clearable
              @input="inputMaxL = /^\d+\.?\d{0,1}$/.test(ruleForm.money) ? null : ruleForm.money.length - 1"
              :disabled="upDate==2?true:false"
              v-model="ruleForm.money"
              style="width: 100%"
              placeholder="请输入目标金额"
              :maxlength="inputMaxL"
          ></el-input>
        </el-form-item>

        <el-form-item prop="coverImg"  label="缩略图">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            图片上传比例为2:1，支持格式png、jpg、jpeg，大小不超过1M
          </p>
          <div>
            <el-upload
                :action="uploadUrl"
                :class="{disabled: uploadDisabled2}"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove2"
                :before-upload="beforeAvatarUploadImg"
                :on-success="shareUpload"
                :file-list="ruleForm.coverImg"
                :limit="1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible"
                append-to-body
                :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </el-form-item>

        <el-form-item prop="goodPhoto" label="轮播图">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            图片上传比例为2:1，支持格式png、jpg、jpeg，大小不超过1M
          </p>
          <div style="display: flex">
            <el-upload
                :action="uploadUrl"
                :class="{
              disabled: uploadDisabled,
            }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="goodsUpload"
                :before-upload="beforeAvatarUploadImg"
                :file-list="ruleForm.goodPhoto"
                :limit="15"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible"
                append-to-body
                :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item label="项目内容" prop="projectDetails">
          <!-- //富文本 -->
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              @ready="ready($event)"
              v-model="ruleForm.projectDetails"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange($event)"
          />
          <span class="wordNumber">{{TiLength}}/4000</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="捐赠记录"
        :visible.sync="donationJL"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <commonTable
          :tableData="donationList"
          @handleSizeChange="handleSizeChange1"
          @handleCurrentChange="handleCurrentChange1"
          :currentPage="currentPage1"
          :loading="loading1"
          :total="total1"
      >
        <template v-slot:table>
          <el-table-column prop="userName" align="center" label="捐赠人" />
          <el-table-column prop="money" align="center" label="捐赠金额" />
          <el-table-column prop="payTime" align="center" label="捐赠时间" />
        </template>
      </commonTable>
    </el-dialog>
  </div>
</template>

<script>
import { lineHeightStyle } from "@/utils/lineHeight.js";
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写内容'

import {addDonation,selectDonationList,upDonation,delDonation,selectDonationDetailsPageList} from "@/api/content";
export default {
  name: "projectList",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputMaxL:10,
      TiLength:0,
      editorOption: quillConfig,
      centerDialogVisible: false,
      donationJL:false,
      ruleForm: {
        projectTitle: "",
        money: "",
        projectDetails:"",
        coverImg: [],
        goodPhoto:[]
      },
      dialogVisible: false,
      rules: {
        projectTitle: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        money: [{ required: true, message: "请输入目标金额", trigger: "blur" }],
        projectDetails: [{ required: true, message: "请输入项目内容", trigger: "blur" }],
        coverImg: [{ required: true, message: "请上传图片"}],
        goodPhoto: [{ required: true, message: "请上传图片"}],
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      options: [{ label: "全部", value: 1 }],
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading:false,
      loading1:false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      uploadUrl: this.$store.state.uploadingUrl,
      dialogImageUrl: "",
      upDate:1,
      donationId:'',
      donationList:[],
      currentPage1: 1,
      total1: 0, //总条数
      pageNum1: 1,
      pageSize1: 10,
      srcList:[]
    };
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.goodPhoto.length >= 5;
    },
    uploadDisabled2() {
      return this.ruleForm.coverImg.length !== 0;
    },
  },
  mounted(){
    this.queryPage();
  },
  methods: {
    ready() {
      Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
    },
    onEditorChange(e){
      // e.quill.deleteText(4000,0);
      if(this.ruleForm.details==''){
        this.TiLength=0
      }else{
        this.TiLength=e.quill.getLength()-1
      }
    },
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectDonationList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        let a = []
        for (let i in list) {
          if (list[i].thumbnail) {
            a.push(list[i].thumbnail)
          }
          list[i].createTime = this.renderTime(list[i].createTime)
          list[i].targetMoney = (list[i].targetMoney/100).toFixed(2)
          if (list[i].completeMoney) {
            list[i].completeMoney = (list[i].completeMoney/100).toFixed(2)
          }
          list[i].progress = parseInt(list[i].progress*100)
          if (list[i].status==0){
            list[i].status = '未开始'
          }else if (list[i].status==1){
            list[i].status = '进行中'
          }else if (list[i].status==2){
            list[i].status = '已完成'
          }
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    //搜索
    async filterNames() {
      this.currentPage = 1;
      let data = {
        name:this.filterName
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    //当前页变更
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.jilu();
    },
    //当前页查询数据条数变更
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.jilu();
    },
    async saveSort(row){
      if(row && row.sort != ""){
        const result = await updateGoodsTypeSort(row);
        this.$message({
          type: "info",
          message: "保存成功",
        });
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    determine(){
      if(this.ruleForm.goodPhoto.length == 0){
        return this.$message({
          message: "商品图片未上传",
          type: "warning",
          center: true,
        });
      }
      if(this.ruleForm.coverImg.length == 0){
        return this.$message({
          message: "商品分享图片未上传",
          type: "warning",
          center: true,
        });
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let arr = this.ruleForm.goodPhoto.map((item) => item.url);
            let data = {
              associationId: localStorage.getItem("associationId"),
              name:this.ruleForm.projectTitle,
              targetMoney:parseInt(this.ruleForm.money * 100),
              content:this.ruleForm.projectDetails,
              thumbnail:this.ruleForm.coverImg && this.ruleForm.coverImg.length != 0
                  ? this.ruleForm.coverImg[0].url
                  : [],
              chart:arr.join(",")
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addDonation(data);
            }else {
              data.donationId = this.donationId
              await upDonation(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delDonation({ donationId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //开始
    async toStart(val){
      let ajax = {
        donationId: val,
        status:1
      }
      try {
        await upDonation(ajax);
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //结束
    async toEnd(val){
      let ajax = {
        donationId: val,
        status:2
      }
      try {
        await upDonation(ajax);
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },

    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    open(val) {
      this.upDate = 2
      this.centerDialogVisible = true;
      let data = JSON.parse(JSON.stringify(val));
      if (data.chart) {
        this.ruleForm.goodPhoto = data.chart.split(",").map((item, index) => {
          return {
            url: item,
            uid: index,
          };
        });
      }
      console.log(this.ruleForm.goodPhoto)
      if (data.thumbnail && data.thumbnail.length !== 2 && data.thumbnail.length !== 0) {
        this.ruleForm.coverImg = [{ url: data.thumbnail, uid: 1 }];
      } else {
        this.ruleForm.coverImg = [];
      }
      this.ruleForm.projectTitle = data.name;
      this.ruleForm.money = data.targetMoney;
      this.ruleForm.projectDetails = data.content;
      this.donationId = data.donationId
    },

    //分享图片
    shareUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.ruleForm.coverImg = arr;
    },
    beforeAvatarUploadImg(file){
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return isLt2M;
    },
    handleRemove(file, fileList) {
      this.ruleForm.goodPhoto = fileList;
    },
    handleRemove2(file, fileList) {
      this.ruleForm.coverImg = fileList;
    },
    // //商品图片
    goodsUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      console.log(fileList)
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });

      this.ruleForm.goodPhoto = arr;
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        projectTitle: "",
        money: "",
        projectDetails:"",
        coverImg: [],
        goodPhoto:[]
      }),
      this.centerDialogVisible = false;
    },
    //捐赠记录
    async jilu(row) {
      this.donationJL = true
      let data = {
        pageSize: this.pageSize1,
        pageNum: this.currentPage1,
        donationId: row.donationId,
        ifPay:0
      };
      try {
        this.loading1 = true;
        const result = await selectDonationDetailsPageList(data);
        this.loading1 = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          list[i].payTime = this.renderTime(list[i].payTime)
          list[i].money = (list[i].money/100).toFixed(2)
        }
        this.donationList = list;
        this.total1 = total;
      } catch (error) {
        this.donationList = [];
        this.loading1 = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

